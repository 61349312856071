<template>
  <MainLayout
    isFullScreen
    class="main-layout--not-found">

    <header>
      <Heading
        :level="'h2'"
        isTextCentered
        class="mb-base">
        {{ $t('artist.dashboard.pageNotFound.notFound') }}
      </Heading>
      <router-link :to="{ name: isArtistUser ? 'ArtistDashboard' : 'ClientDashboard' }">
        <Paragraph
          :size="'sm'"
          isBold
          style="text-decoration: underline;">
          {{ $t('artist.dashboard.pageNotFound.backToDashboard') }}
        </Paragraph>
      </router-link>
    </header>

    <Logo
      :size="'sm'"
      class="mb-sm"
      style="align-self: center">
    </Logo>
  </MainLayout>
</template>

<script>

import { mapGetters }     from 'vuex';

import MainLayout         from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Logo               from '../../../components/atoms/Logo/a-Logo.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph          from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'p-NotFound',
  components: {
    MainLayout,
    Logo,
    Heading,
    Paragraph,
  },
  computed: {
    ...mapGetters({ isArtistUser: 'User/IS_ARTIST_USER' })
  }
}

</script>
